import style from "./subheader.module.scss";
import ApprovalMenu from "./ApprovalMenu";
import LeadMenu from "./LeadMenu";
import HomeMenu from "./HomeMenu";
import ContactMenu from "./ContactMenu";
import ContactDetail from "./ContactDetail";
import LeadDetailMenu from "./LeadDetail";
import FormMenu from "./FormMenu";
import BidDetailMenu from "./BidDetailMenu";
import KvkkMenu from "./KvkkMenu";
import InventoryMenu from "./InventoryMenu";
import InventoryDetail from "./InventoryDetail";
import BidMenu from "./BidMenu";
import NoteDetailMenu from "./NoteDetailMenu";
import ProjectMenu from "./ProjectMenu";
import UserMenu from "./UserMenu";
import UserDetailMenu from "./UserDetailMenu";
import ProjectDetailMenu from "./ProjectDetailMenu";
const SubHeader = ({
  method,
  setNewMethod,
  bidMenu,
  noteDetail,
  noteData,
  AllNoteData,
  bidData,
  approvalMenu,
  approvalData,
  getToggleHideAllColumnsPropsForBids,
  allBidsColumns,
  selectedBid,
  leadData,
  kvkkData,
  inventoryDetail,
  kvkMenu,
  leadDetail,
  createProject,
  editContact,
  setwClick,
  form,
  editBid,
  createInventory,
  leadMenu,
  getToggleHideAllColumnsProps,
  leadRows,
  allColumns,
  homeMenu,
  contactDetail,
  contactMenu,
  onClick,
  setWhoClickProject,
  path,
  newButtonName,
  buttonName,
  owner,
  name,
  surname,
  kvkSub,
  deletes,
  contacData,
  contactRows,
  getToggleHideAllColumnsPropsForContacts,
  ContactAllColumns,
  selectedContact,
  contactDetailData,
  editButtonName,
  createLead,
  userCreate,
  userDetail,
  editLead,
  createContact,
  createBid,
  setBid,
  createBidHistoryId,
  bidDetail,
  bidBackHref,
  selectedMenu,
  setSelectedMenu,
  inventoryMenu,
  selectedInventory,
  inventoryRows,
  getToggleHideAllColumnsPropsFromInventory,
  allInventoryColumns,
  inventoryListData,
  contactListData,
  editInventory,
  inventoryBackHref,
  inventoryDetailData,
  bidDetaillData,
  noteList,
  setSelectedCorporateStatus,
  projectMenu,
  projectData,
  projectDetail,
  projectEdit,
  getToggleHideAllColumnsPropsFromNotes,
  allNotesColumns,
  userMenu,
  userDetailData,
  rapor,
  adminSystem,
}) => {
  return (
    <div className={style.subHeader}>
      {projectDetail ? (
        <ProjectDetailMenu projectData={projectData} />
      ) : userDetail ? (
        <UserDetailMenu userDetailData={userDetailData} />
      ) : leadDetail ? (
        <LeadDetailMenu
          href="/lead-list"
          name={name}
          surname={surname}
          owner={owner}
        />
      ) : form ? (
        <FormMenu
          rapor={rapor}
          setSelectedCorporateStatus={setSelectedCorporateStatus}
          noteList={noteList}
          noteData={AllNoteData}
          setWhoClickProject={setWhoClickProject}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          createInventory={createInventory}
          createBidHistoryId={createBidHistoryId}
          setBid={setBid}
          createContact={createContact}
          editContact={editContact}
          createLead={createLead}
          userCreate={userCreate}
          onClick={onClick}
          editLead={editLead}
          editBid={editBid}
          buttonName={buttonName}
          newButtonName={newButtonName}
          path={path}
          deletes={deletes}
          setwClick={setwClick}
          editButtonName={editButtonName}
          createBid={createBid}
          createProject={createProject}
          projectEdit={projectEdit}
          editInventory={editInventory}
          getToggleHideAllColumnsPropsFromNotes={
            getToggleHideAllColumnsPropsFromNotes
          }
          allNotesColumns={allNotesColumns}
          adminSystem={adminSystem}
        />
      ) : leadMenu ? (
        <LeadMenu
          leadData={leadData}
          getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
          allColumns={allColumns}
          leadRows={leadRows}
        />
      ) : homeMenu ? (
        <HomeMenu />
      ) : contactDetail ? (
        <ContactDetail
          href="/contact-list"
          contactDetailData={contactDetailData}
        />
      ) : contactMenu ? (
        <ContactMenu
          method={method}
          setNewMethod={setNewMethod}
          selectedContact={selectedContact}
          contactListData={contactListData}
          contacData={contacData}
          contactRows={contactRows}
          getToggleHideAllColumnsPropsForContacts={
            getToggleHideAllColumnsPropsForContacts
          }
          ContactAllColumns={ContactAllColumns}
        />
      ) : kvkSub ? (
        <FormMenu />
      ) : bidDetail ? (
        <BidDetailMenu href={bidBackHref} bidDetaillData={bidDetaillData} />
      ) : kvkMenu ? (
        <KvkkMenu kvkkData={kvkkData} />
      ) : inventoryMenu ? (
        <InventoryMenu
          method={method}
          setNewMethod={setNewMethod}
          selectedInventory={selectedInventory}
          inventoryListData={inventoryListData}
          getToggleHideAllColumnsPropsFromInventory={
            getToggleHideAllColumnsPropsFromInventory
          }
          allInventoryColumns={allInventoryColumns}
          inventoryRows={inventoryRows}
        />
      ) : inventoryDetail ? (
        <InventoryDetail
          inventoryDetailData={inventoryDetailData}
          href={inventoryBackHref}
        />
      ) : bidMenu ? (
        <BidMenu
          bidData={bidData}
          getToggleHideAllColumnsPropsForBids={
            getToggleHideAllColumnsPropsForBids
          }
          allBidsColumns={allBidsColumns}
          selectedBid={selectedBid}
        />
      ) : approvalMenu ? (
        <ApprovalMenu approvalData={approvalData} />
      ) : noteDetail ? (
        <NoteDetailMenu noteData={noteData} />
      ) : projectMenu ? (
        <ProjectMenu
        // leadData={leadData}
        // getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
        // allColumns={allColumns}
        />
      ) : userMenu ? (
        <UserMenu />
      ) : null}
    </div>
  );
};

export default SubHeader;

import { useState } from "react";

import style from "./sidefilter.module.scss";
const DateInput2 = ({ title, value, setChange, resetField, setData, data }) => {
  const [isOpen, setOpen] = useState();
  const toggle = (e) => {
    setData({
      ...data,
      [resetField]: "",
    });
    setOpen(e.target.checked);
  };
  return (
    <>
      <label className={style.input}>
        <input onChange={(e) => toggle(e)} type="checkbox" />
        <h3>{title} </h3>
      </label>
      {isOpen ? (
        <input
          style={{ marginBottom: "0.5rem" }}
          type="date"
          onChange={setChange}
        />
      ) : null}
    </>
  );
};

export default DateInput2;

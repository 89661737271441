import axios from "axios";
import { BaseUrl } from "../ApiConfig";

export const getAllContacts = async (
  jwt,
  userType,
  pageNumber = 1,
  pageSize = 25,
  method = "get",
  data = {}
) => {
  let request;
  if (method === "get") {
    request = await axios.get(
      `${BaseUrl}/api/contact/getall/${userType}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === "post") {
    request = await axios.post(
      `${BaseUrl}/api/contact/queryContact?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  } else if (method === "excel") {
    request = await axios.post(
      `${BaseUrl}/api/contact/queryContact?pageNumber=1&pageSize=1000000`,
      data,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
  }

  const payload = {
    data: request.data.contact,
    pageCount: request.data.pageCount,
  };

  return payload;
};

export const deleteAContact = async (jwt, contactId) => {
  const request = await axios.delete(
    `${BaseUrl}/api/contact/delete/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

export const getAContact = async (jwt, contactId) => {
  const request = await axios.get(
    `${BaseUrl}/api/contact/findone/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
  return request;
};

import React, { useEffect, forwardRef } from "react";

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, checked, ischecked, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    // ischecked prop'unu DOM'a geçirmiyoruz
    const { ischecked: _, ...domProps } = rest;

    return (
      <input
        type="checkbox"
        ref={resolvedRef}
        checked={checked || ischecked === "true"}
        {...domProps}
      />
    );
  }
);

export default IndeterminateCheckbox;

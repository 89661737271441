/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { ProcessButton, NewButton } from "./Buttons";
import style from "./subheader.module.scss";
import axios from "axios";
import Popup from "../LeadElements/Popup";
import { useNavigate } from "react-router";
//import { selectKvkk } from "../../redux/actions";
import { BaseUrl } from "../../ApiConfig";
import ExcelExportPopup from "./ExcelExportPopup.jsx";
import { DownIcon, UpIcon } from "../../assets/icons";
import IndeterminateCheckbox from "../table/indeterminateCheckbox/IndeterminateCheckbox";
import { useSelector } from "react-redux";
import { sendLeadToContact } from "../../lib/leadApi";
import LeadPopUp from "../../containers/LeadList/LeadPopUp";
import LeadPopup from "../../containers/CreateLead/LeadPopup";
import { useTranslation } from "react-i18next";

const CheckBox = ({ getToggleHideAllColumnsProps, allColumns }) => {
  const [select, setSelect] = useState(false);
  const { t } = useTranslation();
  const checkboxMenu = useRef(null);

  useEffect(() => {
    const closeOpenMenus = (e) => {
      if (
        checkboxMenu.current &&
        select &&
        !checkboxMenu.current.contains(e.target)
      ) {
        setSelect(false);
      }
    };

    document.addEventListener("mousedown", closeOpenMenus);
    return () => document.removeEventListener("mousedown", closeOpenMenus);
  }, [select]);

  const toggleSelect = () => setSelect(!select);

  return (
    <div className={style.checkboxContact} ref={checkboxMenu}>
      <button className={style.button} onClick={toggleSelect}>
        {t("kolonSeciniz")}
        {select ? <UpIcon /> : <DownIcon />}
      </button>
      {select && (
        <div className={style.select}>
          <div>
            <IndeterminateCheckbox
              {...getToggleHideAllColumnsProps()}
              indeterminate={getToggleHideAllColumnsProps().indeterminate}
              ischecked={
                getToggleHideAllColumnsProps().checked ? "true" : "false"
              }
              onChange={(e) => {
                getToggleHideAllColumnsProps().onChange(e);
              }}
            />
            {t("hepsiniSec")}
          </div>
          {allColumns.map(({ id, Header, getToggleHiddenProps }) => (
            <div key={id}>
              <label>
                <input type="checkbox" {...getToggleHiddenProps()} /> {Header}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const LeadMenu = ({
  leadData,
  getToggleHideAllColumnsProps,
  allColumns,
  leadRows,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [selectValue, setSelectValue] = useState();
  const [popup, setPopup] = useState(false);
  const [kvkPopup, setKvkPopup] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [excelPopup, setExcelPopup] = useState(false);
  const [headers, setHeaders] = useState([]);

  const [count, setCount] = useState(0);
  const [type, setType] = useState("");
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState("");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [targetUserIds, setTargetUserIds] = useState([]);
  const [targetProject, setTargetProject] = useState([]);
  const [sendOwner, setSendOwner] = useState({
    ownerName: "",
    ownerId: "",
  });

  const [addPopup, setAddPopup] = useState(false);
  const [errorText, setErrorText] = useState("");

  const successModalAddHandler = () => {
    const selectedRows = leadRows.filter((row) => row.isSelected);

    if (selectedRows.length === 0) return;

    const allSameProject = selectedRows.every(
      (row) =>
        row.original.associatedProject[0]._id ===
        selectedRows[0].original.associatedProject[0]._id
    );

    if (!allSameProject) {
      setErrorText(
        "Seçilen kayıtlar farklı projelere ait. Lütfen aynı projeye ait kayıtları seçin."
      );
      setAddPopup(false);
      return;
    }

    const firstSelected = selectedRows[0].original;

    setTargetUserIds(selectedRows.map((row) => row.original._id));
    setModalType("send");
    setTargetProject(firstSelected.associatedProject);
    setSendOwner({
      ownerName: firstSelected.ownerName,
      ownerId: firstSelected.owner,
    });
    setIsPopupOpen(true);
    setAddPopup(false);
  };

  const successModalHandler = async () => {
    // Get selected IDs using array methods instead of forEach
    const arrayIds = leadRows
      .filter((element) => element.isSelected)
      .map((element) => element.original._id);

    if (arrayIds.length === 0) {
      return;
    }

    try {
      if (type === "delete") {
        await axios.delete(`${BaseUrl}/api/lead/delete/${arrayIds}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        // Refresh the page more elegantly (consider using a state update instead)
        window.location.reload();
      }
    } catch (error) {
      console.error("Error processing leads:", error);
      // Consider adding proper error handling here
    } finally {
      // Clean up state regardless of success/failure
      setType("");
      setPage("");
      setIsError(false);
      setPopup(false);
    }
  };

  const popUpClose = () => {
    setErrorText("");
    setModalType("");
    setTargetUserIds([]);
    setTargetProject([]);
    setSendOwner({
      ownerName: "",
      ownerId: "",
    });
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (selectValue === "delete") {
      setPopup(true);
      setType("delete");
      setPage("lead");
      const isElelemtSelected = leadRows.some((row) => row.isSelected);
      if (isElelemtSelected) {
        setCount(leadRows.filter((d) => d.isSelected).length);
      } else {
        setIsError(true);
      }
      setSelectValue("default");
    }
    if (selectValue === "add") {
      setAddPopup(true);
      setType("add");
      setPage("lead");
      const isElelemtSelected = leadRows.some((row) => row.isSelected);
      if (isElelemtSelected) {
        setCount(leadRows.filter((d) => d.isSelected).length);
      } else {
        setIsError(true);
      }
      setSelectValue("default");
    }
  }, [selectValue]);

  const excelExportAndDownload = (data) => {
    setExcelPopup(true);
    setHeaders([
      t("isim"),
      t("soyisim"),
      t("telefon"),
      t("email"),
      t("not"),
      t("sirket"),
      t("olusturulmaTarihi"),
      t("olusturanPersonel"),
      t("kullanciTipi"),
    ]);

    let isim = t("isim");
    let soyisim = t("soyisim");
    let telefon = t("telefon");
    let eposta = t("email");
    let not = t("not");
    let sirket = t("sirket");
    let olusturulmaTarihi = t("olusturulmaTarihi");
    let olusturanPersonel = t("olusturanPersonel");
    let kullanciTipi = t("kullanciTipi");

    const newData = data.map((item) => {
      return {
        [isim]: item.name,
        [soyisim]: item.surname,
        [telefon]: item.phone,
        [eposta]: item.email,
        [not]: item.note,
        [sirket]: item.company,
        [olusturulmaTarihi]: item.createdAt,
        [olusturanPersonel]: item.owner,
        [kullanciTipi]: item.userType,
      };
    });
    setExcelData(newData);
  };
  const exelDownloaded = () => {
    setExcelPopup(false);
  };

  const closeModalHandler = () => {
    setType("");
    setPage("");
    setIsError(false);
    setPopup(false);
    setAddPopup(false);
  };

  const successClick = () => {
    if (modalType === "") {
      return;
    } else if (modalType === "send") {
      sendLeadToContact(user.token, targetUserIds, user.userType, sendOwner)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
      window.location.reload();
    }

    setModalType("");
    setTargetUserIds([]);
    setTargetProject([]);
    setSendOwner({
      ownerName: "",
      ownerId: "",
    });

    setIsPopupOpen(false);
  };

  return (
    <>
      {errorText && <LeadPopup closeClick={popUpClose} errorText={errorText} />}
      {isPopupOpen && (
        <LeadPopUp
          type={modalType}
          closeClick={popUpClose}
          successClick={successClick}
          fullName={"Seçili leadler"}
          sendOwner={sendOwner}
          associatedProject={targetProject}
        />
      )}
      {addPopup ? (
        <Popup
          closeClick={closeModalHandler}
          count={count}
          type={type}
          page={page}
          isError={isError}
          successClick={successModalAddHandler}
        />
      ) : null}
      {popup ? (
        <Popup
          closeClick={closeModalHandler}
          count={count}
          type={type}
          page={page}
          isError={isError}
          successClick={successModalHandler}
        />
      ) : null}
      {excelPopup ? (
        <ExcelExportPopup
          closeClick={exelDownloaded}
          headers={headers}
          paddingVal="40px 10px"
          data={excelData}
          fileName={`${t("iliskiler")}`}
          text={t("excelExport")}
        />
      ) : null}

      {kvkPopup ? (
        <Popup
          closeClick={setKvkPopup}
          isKvkExistTitle={kvkPopup}
          //successClick={deleteCustomerByIds}
        />
      ) : null}

      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            {t("iliskiler")}
          </div>
        </div>
        <div className={style.right}>
          <CheckBox
            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
            allColumns={allColumns}
          />
          {/* {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("topluVeriYukle")}
              // onClick={() => window.location.replace("/create-lead")}
            />
          )} */}
          {(user.department === "admin" || user.department === "ceo") && (
            <NewButton
              title={t("veriyiDisaAktar")}
              icon={"exel"}
              onClick={() => {
                excelExportAndDownload(leadData);
              }}
            />
          )}
          {(user.department !== "hr" ||
            user.department !== "fd" ||
            user.department !== "mc" ||
            user.department !== "dms" ||
            user.department !== "pd") && (
            <NewButton
              title={t("yeniIliskiEkle")}
              onClick={() => {
                navigate("/create-lead", { reaplce: true });
                // window.location.replace('/create-lead')
              }}
            />
          )}
          {(user.department === "admin" ||
            user.department === "ceo" ||
            user.department === "gm" ||
            user.department === "sd" ||
            user.department === "rd" ||
            user.department === "sm") && (
            <ProcessButton
              title={t("cokluIslemler")}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LeadMenu;

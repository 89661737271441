/* eslint-disable react-hooks/exhaustive-deps */
// import { useState, useEffect } from "react";
// import { ProcessButton5, NewButton } from "./Buttons";
import { NewButton } from "./Buttons";
import style from "./subheader.module.scss";
// import axios from "axios";
// import Popup from "../LeadElements/Popup";
import { useNavigate } from "react-router";
// import { useSelector } from "react-redux";
// import { selectKvkk } from "../../redux/actions";
// import { BaseUrl } from "../../ApiConfig";

const UserMenu = () => {
  // const user = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  // const [selectValue, setSelectValue] = useState();
  // const [popup, setPopup] = useState(false);
  // const [kvkPopup, setKvkPopup] = useState(false);
  // const [excelData, setExcelData] = useState([]);
  // const [excelPopup, setExcelPopup] = useState(false);
  // const [headers, setHeaders] = useState([]);

  // const deleteCustomerByIds = () => {
  //   let arrayids = [];
  //   leadData.forEach((d) => {
  //     if (d.select) {
  //       arrayids.push(d.id);
  //     }
  //   });
  //   axios
  //     .delete(`${BaseUrl}/api/lead/delete/${arrayids}`)
  //     .then((data) => {
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     })
  //     .catch((err) => alert(err.response.data));
  // };
  // const pushContact = () => {
  //   const userType = user.userType;

  //   let arrayids = [];
  //   leadData.forEach((d) => {
  //     if (d.select) {
  //       arrayids.push(d.id);
  //     }
  //   });
  //   axios
  //     .post(`${BaseUrl}/api/contact/addcontact/${arrayids}/${userType}`)
  //     .then((response) => {
  //       if (response.data.success === true) {
  //         navigate("/contact-list", { replace: true });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response.data.message === "Kvk bulunamadı") {
  //         setKvkPopup(true);
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (selectValue === "delete") {
  //     setPopup(true);
  //   }
  //   if (selectValue === "kalifiye") {
  //     pushContact();
  //   }
  //   if (selectValue === "kvkk") {
  //     dispatch(
  //       selectKvkk(
  //         leadData.filter((item) => item.select).map((item) => item.kvkkId)
  //       )
  //     );
  //   }
  // }, [selectValue]);

  return (
    <>
      <div className={style.leadMenu}>
        <div className={style.left}>
          <div
            style={{
              marginLeft: "26px",
              width: "100%",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            Kullancılar Veritabanı
          </div>
        </div>
        <div className={style.right}>
          <NewButton
            title={"Yeni Kullancı Ekle"}
            onClick={() => {
              navigate("/admin/kullanici-olustur", { replace: true });
              //window.location.replace('/project-create')
            }}
          />
          {/* <ProcessButton5
            title={"Çoklu İşlemler"}
            selectValue={selectValue}
            setSelectValue={setSelectValue}
          /> */}
        </div>
      </div>
    </>
  );
};

export default UserMenu;
